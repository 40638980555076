.bg {
    background-color: #fff;
    height: 100vh;
    display: flex;
    align-items: center;
}

.bg-img-1 {
    position: absolute;
    top: 0;
    right: 50vh;
    height: 9rem;
}

.bg-img-2 {
    position: absolute;
    top: 0;
    right: 0;
    height: 9rem;
}

.bg-img-3 {
    position: absolute;
    right: 0;
    bottom: 0;
    height: 9rem;
}

.agentlogin {
    position: absolute;
    top: 2;
    right:10%;
    height: 15rem;
}
/*.backoffice{
    text-align: left;
    background-color: aqua;
}
.alignleft {
    width: 25%;
    flex: 1;
    text-align: left;
    }
    .alignright {
        width: 50%;
        flex: 1;
        text-align: center;
        }*/
.logo {
    position: absolute;
    left: 5vw;
    top: 5vh;
    height: 3rem;
    flex-shrink: 1;
}

#formId {
    position: absolute;
    left: 15vw;
    margin-top: 1rem;
}

.btn {
    margin-top: 30rem;
    height: 3rem;
}

.input-grp {
    width: 20rem;
}

.subTxt {
    margin-top: 0.8rem;
    margin-bottom: 3rem;
    color: var(--sec-grey-color);
}
div .left {
    align-items: start;
   float: left;
   justify-content: left;
   
}
.right {

    float: right;
    color: black;
 }

@media only screen and (max-width: 600px) {
    .imgLoginPgTrk {
        display: none;
    }

    #formId {
        position: relative;
        justify-content: center;
        width: 17rem;
    }

    .input-grp {
        width: 17rem;
    }
}