@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap');

:root {
    --primary-color: #5E47DD;
    --bg-color: #F5F5F5;
    --sec-grey-color: #949494;
    --border-grey-color: "#E3E3E3";
    --pri-color: "#8392AB";
}

* {
    box-sizing: border-box;
}

body {
    margin: 0px;
    padding: 0px;
    font-family: "Outfit", "Arial", sans-serif;
    background-color: var(--bg-color);
    height: 100%;
    width: 100%
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    -webkit-border-radius: 5px;
    border-radius: 5px;
}

::-webkit-scrollbar-thumb {
    -webkit-border-radius: 5px;
    border-radius: 5px;
    background-color: rgba(94, 71, 221, 0.5)
}

::-webkit-scrollbar-thumb:window-inactive {
    background-color: rgba(94, 71, 221, 0.5)
}

.slick-prev:before,
.slick-next:before {
    font-size: 30px;
    color: rgba(43, 163, 255);

}

.slick-prev {
    left: 0% !important;
    z-index: 1;
}

.slick-next {
    right: 2% !important;
    z-index: 1;
}

